import React from "react"
import { Link } from "gatsby"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import "../../css/custom-css/custom-products-services.css"
import tile1 from "../../images/products-and-services/tiles-4.webp"
import tile2 from "../../images/products-and-services/tiles-8.webp"
import tile3 from "../../images/products-and-services/tiles-3.webp"
import tile4 from "../../images/products-and-services/tiles-9.webp"
import tile5 from "../../images/products-and-services/tiles-10.webp"

const ServicesTiles = () => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
  return (
    <div className="bg-white">
      <div className="content-block">
        <div className="section-full">
          <div className="row spno about-industry">
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile1} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            >
              <div className="service-box style2">
                <div>
                  <h2 className="title">
                    {intl.formatMessage({
                      id: "header.cmo",
                    })}
                  </h2>
                  <p className="service-tile-content">
                    {intl.formatMessage({
                      id: "productsandservices.services.cmo",
                    })}
                  </p>
                  <Link
                    to={`${locale}/products-and-services/services/cmo`}
                    className="site-button outline-2 btnhover11"
                  >
                    {intl.formatMessage({
                      id: "productsandservices.services.btn",
                    })}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile3} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            >
              <div className="service-box style2">
                <div>
                  <h2 className="title">
                    {intl.formatMessage({
                      id: "header.tollintesting",
                    })}
                  </h2>
                  <p className="service-tile-content">
                    {intl.formatMessage({
                      id: "productsandservices.services.tollintesting.1",
                    })}
                  </p>
                  <Link
                    to={`${locale}/products-and-services/services/toll-in-testing`}
                    className="site-button outline-2 btnhover11"
                  >
                    {intl.formatMessage({
                      id: "productsandservices.services.btn",
                    })}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile2} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            >
              <div className="service-box style2">
                <div>
                  <h2 className="title">
                    {intl.formatMessage({
                      id: "header.tollintesting",
                    })}
                  </h2>
                  <ol className="service-tile-content">
                    <li
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            "productsandservices.services.tollintesting.2.1",
                        }),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            "productsandservices.services.tollintesting.2.2",
                        }),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            "productsandservices.services.tollintesting.2.3",
                        }),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            "productsandservices.services.tollintesting.2.4",
                        }),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            "productsandservices.services.tollintesting.2.5",
                        }),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            "productsandservices.services.tollintesting.2.6",
                        }),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            "productsandservices.services.tollintesting.2.7",
                        }),
                      }}
                    ></li>
                    {intl.formatMessage({
                      id: "productsandservices.services.tollintesting.2.8",
                    })}
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile4} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            >
              <div className="service-box style2">
                <div>
                  <h2 className="title">
                    {intl.formatMessage({
                      id: "header.processandanalyticaldevelopment",
                    })}
                  </h2>
                  <p className="service-tile-content">
                    {intl.formatMessage({
                      id:
                        "productsandservices.services.processandanalyticaldevelopment",
                    })}
                  </p>
                  <Link
                    to={`${locale}/products-and-services/services/process-analytical-development`}
                    className="site-button outline-2 btnhover11"
                  >
                    {intl.formatMessage({
                      id: "productsandservices.services.btn",
                    })}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile5} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesTiles
