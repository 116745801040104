import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import "../../css/custom-css/custom-products-services.css"

import Layout from "../../components/custom-components/Layout"
import ProductSlider from "../../components/custom-components/ProductSlider"
import BannerHeader from "../../components/custom-components/BannerHeader"
import ServiceTiles from "../../components/custom-components/ServicesTiles"

import bg from "../../images/products-and-services/header-products-and-services.webp"
import Seo from "../../components/seo"

const query = graphql`
  {
    allContentfulProduct(
      sort: { order: ASC, fields: updatedAt }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        id
        title
        images {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            cropFocus: CENTER
            height: 834
            width: 1500
            resizingBehavior: FILL
          )
        }
      }
    }
  }
`

const ProductsAndServices = () => {
  const intl = useIntl()
  const {
    allContentfulProduct: { nodes: products },
  } = useStaticQuery(query)

  return (
    <Layout page="productsPage">
      <Seo
        title={intl.formatMessage({
          id: "header.productsandservices",
        })}
      />

      <BannerHeader
        background={bg}
        title={intl.formatMessage({
          id: "header.productsandservices",
        })}
      />
      <div className="section-head text-black text-center container">
        <p className="head-content">
          {intl.formatMessage({
            id: "productsandservices.banner.desc",
          })}
        </p>
      </div>
      <div className="page-content bg-gray frame-box">
        <div className="container">
          <div className="section-full content-inner-custom frame-project-area">
            <div className="section-head text-center style2">
              <h2 className="title">
                {intl.formatMessage({
                  id: "productsandservices.products.title",
                })}
              </h2>
            </div>
            <ProductSlider products={products} />
          </div>
        </div>
      </div>
      <ServiceTiles />
    </Layout>
  )
}

export default ProductsAndServices
